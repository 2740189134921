import React from 'react'


const Loading = () => (
	<div style={{position: "absolute", top: "50%", left: "50%", marginLeft: "-4rem", marginTop: "-4rem"}}>
		<div className="spinner-grow text-primary" style={{width: "8rem", height: "8rem"}} role="status">
			<span className="sr-only">Loading...</span>
		</div>
	</div>
)


export default Loading