import React from 'react'
import { NavLink, Switch, Route } from 'react-router-dom'
import { Row, Col, Card, CardHeader, Nav } from 'reactstrap'
import { useUserContext } from 'loxamtraining-core'

import { Page, PageSection } from '../../lib/apptheme'
import { SettingsProfile, SettingsSecurity, SettingsMembers, SettingMembersEditModal, SettingMembersAddModal } from './'


const Settings: React.FC = () => {
	const user: any | null = useUserContext()

	return (
		<Page>
			<PageSection>
				<Row>
					<Col lg="4">
						<Card>
							<CardHeader>Paramètres</CardHeader>
							<Nav vertical tabs>
								<NavLink to="/settings" exact className="nav-link" activeClassName="active">Compte</NavLink>
							</Nav>
							<Nav vertical tabs>
								<NavLink to="/settings/security" className="nav-link" activeClassName="active">Sécurité</NavLink>
							</Nav>
							{
								user && user.roles.admin && (
									<Nav vertical tabs>
										<NavLink to="/settings/members" className="nav-link" activeClassName="active">Membres</NavLink>
									</Nav>
								)
							}
						</Card>
					</Col>
					<Col>
						<Switch>
							<Route path="/settings" exact component={SettingsProfile} />
							<Route path="/settings/security" exact component={SettingsSecurity} />
							<Route path="/settings">
								<Route path="/settings/members" component={SettingsMembers} />
								<Switch>
									<Route path="/settings/members/add" exact strict component={SettingMembersAddModal} />
									<Route path="/settings/members/:id" exact strict component={SettingMembersEditModal} />
								</Switch>
							</Route>
						</Switch>
					</Col>
				</Row>
			</PageSection>
		</Page>
	)
}



export default Settings