import React from 'react'

const Page: React.FC = ({children}) => (
	<div className="page">
		<div className="page-inner">
			{children}
		</div>
	</div>
)

export default Page