const getSetting = () => {
	if (process.env.REACT_APP_ENV === 'prod') {
		return {
			firebaseConfig: {
				apiKey: "AIzaSyDy2RJkjMQTIcQeEKZBpO4OZx_iDOKv6yM",
				authDomain: "sdp-production.firebaseapp.com",
  				databaseURL: "https://sdp-production.firebaseio.com",
  				projectId: "sdp-production",
  				storageBucket: "sdp-production.appspot.com",
  				messagingSenderId: "921103875717",
  				appId: "1:921103875717:web:9ed10d859b3aae979733ec",
  				measurementId: "G-51M2V3SS11"
			},
			checkoutUrl: "https://checkout.paiementloxam.fr"
		}
	} else {
		console.log('tu es en dev')
		return {
			firebaseConfig: {
				apiKey: "AIzaSyB73BRK75859juBqNMacoY2M9bwgk0QAE4",
				authDomain: "sdp-staging-2c61e.firebaseapp.com",
				databaseURL: "https://sdp-staging-2c61e.firebaseio.com",
				projectId: "sdp-staging-2c61e",
				storageBucket: "sdp-staging-2c61e.appspot.com",
				messagingSenderId: "1034799166534",
				appId: "1:1034799166534:web:df2d9d1e1a8c36edfc8647"
			},
			checkoutUrl: "http://localhost:3001"
		}
	}
}


export default getSetting()