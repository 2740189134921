import React from 'react'

import ReactStrapComponent from './ReactStrapComponent'
import Field from '../Field'


const TextField = (props:any) => <Field type="text" component={ReactStrapComponent} {...props} />



export default TextField