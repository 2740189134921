import React, { useState, useEffect } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import moment from 'moment'
import { Card, CardBody, Button } from 'reactstrap'
import { TextData } from 'loxamtraining-data'

import { Page, PageSection, PageTitleBar, Table } from '../../lib/apptheme'


const displayAmount = (amount: any) => {

    let price: string = (amount / 100).toFixed(2);
    
    let numberFormat = new Intl.NumberFormat(['fr-FR'], {
      style: 'currency',
      currency: "EUR",
      currencyDisplay: 'symbol',
    })

    return numberFormat.format(parseFloat(price))
  }
const renderPaymentCreatedEvent = (data: any) => {
	if (data.type === "capture") {
		return `Un nouveau paiement de type capture d'un montant de ${displayAmount(data.paymentIntent.amount)} a été créé`
	} else if (data.type === "deposit") {
		return `Un nouveau paiement de type caution d'un montant de ${displayAmount(data.paymentIntent.amount)} a été créé`
	} else {
		return "Un nouveau paiement a été créé"
	}
}


const eventMessages: any = {
	"payment.created" : renderPaymentCreatedEvent,
	"sms.sended": (data: any) => (`Le lien du paiement a été envoyé par sms`),
	"payment.metadata_updated": (data: any) => (`Les informations du paiement ont été mise à jour`),
	"payment.amount_capturable_updated": (data: any) => ("Le client a validé le paiement"),
	"payment.succeeded": (data: any) => (`La capture d'un montant de ${displayAmount(data.paymentIntent.amount_received)} a réussi`),
	"payment.captured": (data: any) => (`La capture d'un montant de ${displayAmount(data.paymentIntent.amount_received)} a réussi`),
	"payment.canceled": (data: any) => ("La paiment a été annulé"),
	"sms.ar_status": (data: any) => (`Le client a bien reçu le lien pour le paiement`),
	"payment.expired": (data: any) => "La caution a expirée",
	"report.dayly_report_sended": (data: any) => "Le raport quotidien des transactions a été envoyé"
}


const getDoc = (docSnap: any) => {
	return Object.assign({id: docSnap.id}, docSnap.data())
}

const UnixDateTimeData = (props: any) => <React.Fragment>{props.data ? moment.unix(props.data.seconds).format("DD/MM/YYYY à HH:mm") : "-"}</React.Fragment>

const EventDescriptionData = (props: any) => (
		<React.Fragment>
			{
				props.data ? (
					eventMessages[props.data.event] ? eventMessages[props.data.event](props.data.data) : props.data.event
				) : "-"
			}
		</React.Fragment>
	)




const usePaginateCollectionFirestore = (ref: any) => {
	const [isLoading, setLoading] = useState(true)
	const [data, setData]: [any, any] = useState([])
	const [last, setLast]: [any, any] = useState(null)
	const [count, setCount]: [any, any] = useState(20)

	let queryRef = ref.limit(20)

	const fetchData = async () => {
		if (last) {
			queryRef = queryRef.startAfter(last.data().timestamp)
		}

		const querySnap = await queryRef.get()

		setCount(querySnap.docs.length)
		setLast(querySnap.docs[querySnap.docs.length - 1])
		setData(data.concat(querySnap.docs.map((docSnap: any) => getDoc(docSnap))))
		setLoading(false)
	}

	return [data, isLoading, count, fetchData]
}


/*
 * List Event view
 */
export const EventList = () => {
	const ref = firebase.firestore().collection('/events').orderBy('timestamp', 'desc')
	const [data, isLoading, count, fetchData] = usePaginateCollectionFirestore(ref)

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Evénements</h1>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Table data={data} isLoading={isLoading}>
							<EventDescriptionData label="Evénement" />
							<UnixDateTimeData name="timestamp" label="Date" />
						</Table>
						{
							count === 20 && isLoading === false && <Button type="button" onClick={() => fetchData()}>Suivant</Button>
						}
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}