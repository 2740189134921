import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert } from 'reactstrap'

import { Form, EmailField } from 'loxamtraining-form'

import defaultLogo from '../assets/images/brand-logo.svg'


interface ForgotPasswordProps {
	handleForgotPassword: any,
	logo?: any
}


const ForgotPassword: React.FC<ForgotPasswordProps> = ({handleForgotPassword, logo}) => {
	const [submitSucceeded, setSubmitSucceeded] = useState(false)

	const handleSubmit = (values: any, actions: any) => {
		handleForgotPassword(values.email).then(() => {
			setSubmitSucceeded(true)
		}).catch((error: any) => {
			error && error.message ? (
				actions.setStatus(error.message)
			) : (
				actions.setStatus("Erreur lors de la demande de réinitialisation de mot de passe")
			)
			
			actions.setSubmitting(false)
		})
	}

	return (
		<main className="auth">
			<header id="auth-header" className="auth-header" >
				<h1>
					<img src={logo ? logo : defaultLogo} height="28" alt="" />
					<span className="sr-only">Sign In</span>
				</h1>
			</header>
			<div className="auth-form">
				{
					submitSucceeded ? (
						<Alert color="success">
							<p>Merci ! Nous avons bien pris en compte votre demande de réinitialisation de mot de passe.</p>
							<p>Des instructions vous ont été envoyés par mail.</p>
						</Alert>
					) : (
						<React.Fragment>
							<p className="text-center">
								Merci de renseigner votre email pour recevoir le lien de réinitialisation de mot de passe.
							</p>
							<Form onSubmit={handleSubmit} initialValues={{email: ""}} buttonName="M'envoyer le lien" buttonClassName="btn btn-lg btn-primary btn-block">
								<EmailField name="email" placeholder="Email" required />
							</Form>
						</React.Fragment>
					)
				}
			</div>
			<div className="text-center pt-3">
				<Link to="/login" className="link">Se connecter</Link>
			</div>
		</main>
	)
}

export default ForgotPassword