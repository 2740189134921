import React from 'react'
import { Link } from 'react-router-dom'

interface EditDataProps {
	name?: string,
	action?: boolean,
	data?: string
	path?: string
}

const EditData: React.FC<EditDataProps> = (props: any) => (
	<Link to={`${props.path}/${props.data}`} className="btn btn-sm btn-icon btn-secondary">
		<i className="fa fa-pencil-alt"></i>
		<span className="sr-only">Edit</span>
	</Link>
)

EditData.defaultProps = {
	action: true
}

export default EditData