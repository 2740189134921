import React from 'react'
import { FieldProps as FieldFormikProps} from 'formik'
import { FormGroup, Label, Input, InputProps, FormText } from 'reactstrap'
import _ from 'lodash'

interface FieldProps extends FieldFormikProps, InputProps {
	label?: string,
	helptext?: string,
	name: string,
	form: any
	options?: any[]
}

const ReactStrapComponent: React.FC<FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
	const error = _.get(errors, field.name)
	const isTouched = _.get(touched, field.name)
	
	const renderError = () => {
		return (isTouched && error) || (props.type === "select" && error)
	}

	return (
	  	<FormGroup>
			{props.label && <Label for={props.id}>{props.label} {props.required && "*"}</Label>}
			{
				props.children ? (
					<Input {...field} {...props} className={renderError() ? "is-invalid" : ""}>
						{props.children}
						{props.options && props.options.map(option => <option value={option.value}>{option.label}</option>)}
					</Input>
				) : (
					<Input {...field} {...props} className={renderError() ? "is-invalid" : ""} />				)
			}
			{renderError() && <FormText color="error" className="invalid-feedback">{error}</FormText>}
			{props.helptext && <FormText>{props.helptext}</FormText>}
		</FormGroup>
	)
}


export default ReactStrapComponent