import React from 'react'
import { useToasts } from 'react-toast-notifications'
import { Form } from 'loxamtraining-form'

interface FormSetProps {
	data: any,
	isLoading?: boolean,
	handleSubmit: (values: any, actions: any) => Promise<any>,
	hideSubmitButton?: boolean
}

const FormSetProps: React.FC<FormSetProps> = props => {
	const { addToast } = useToasts()

	const handleSubmit = async (values: any, actions: any) => {
		try {
			await props.handleSubmit(values, actions)
			console.log('save succeed')
			addToast("Succès de l'enregistrement", { appearance: 'success' })
		} catch(error) {
			if (error.message) {
				actions.setStatus(error.message)
			} else {
				actions.setStatus("Erreur lors de la capture")
			}
			
			addToast("Echec de l'enregistrement", { appearance: 'error' })
		}
		actions.setSubmitting(false)
	}

	return (
		props.isLoading ? (<p>Chargement en cours...</p>) : (
			<Form onSubmit={handleSubmit} initialValues={props.data} hideSubmitButton={props.hideSubmitButton}>
				{props.children}
			</Form>
		)
	)
}

FormSetProps.defaultProps = {
	isLoading: false
}

export default FormSetProps