import React from 'react'

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

import { User } from '../Interfaces'


interface UserDropdownProps {
	isOpen: boolean,
	toggle: () => void,
	user: User
}

const UserDropdown: React.FC<UserDropdownProps> = ({isOpen, toggle, user, children}) => (
	<Dropdown tag="div" className="d-flex" isOpen={isOpen} toggle={toggle} inNavbar nav direction="down">
		<DropdownToggle className="btn-account d-none d-md-flex" tag="button" caret>
        	<span className="account-summary pr-lg-4 d-none d-lg-block">
				<span className="account-name">{user.name}</span>
				{user.accountDescription && <span className="account-description">{user.accountDescription}</span>}
    		</span>
		</DropdownToggle>
		<DropdownMenu id="user-dropdown">
			<div className="dropdown-arrow"></div>
			{children}
		</DropdownMenu>
	</Dropdown>
)

export default UserDropdown