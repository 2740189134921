import React, { useState, createElement } from 'react'

import { Header, Aside } from '../'

//Interface
import { LevelMenu, User } from '../Interfaces'

import 'open-iconic/font/css/open-iconic-bootstrap.scss'
import '@fortawesome/fontawesome-free/css/all.css'

import '../assets/scss/theme.scss'
import '../assets/scss/custom.scss'



interface DefaultLayoutProps {
    asideMenu: LevelMenu[],
    user: User,
    userDropdownItems?: any,
    headerNavs?: any[],
    headerComponent?: any,
    logo: any
}


const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, headerComponent, asideMenu, user, userDropdownItems, headerNavs, logo }) => {
    const [collapsed, setCollapsed] = useState(false)

    const toggleNavbar = () => setCollapsed(!collapsed)

    return (
        <div className="app">
            {headerComponent ? createElement(headerComponent, {toggle: toggleNavbar}) : <Header toggle={toggleNavbar} user={user} headerNavs={headerNavs} userDropdownItems={userDropdownItems} logo={logo} />}
            <Aside asideMenu={asideMenu} show={collapsed} user={user} userDropdownItems={userDropdownItems} />
            <main className="app-main">
                <div className="wrapper">
                    { children }
                </div>
            </main>
            <div className={`aside-backdrop ${collapsed && "show"}`} onClick={() => setCollapsed(!collapsed)}></div>
        </div>
    )
}


export default DefaultLayout