import React, { useState, MouseEvent } from 'react'
import { Link, useLocation} from 'react-router-dom'
import { Collapse } from 'reactstrap'

//Interface
import { LevelMenu, User } from '../Interfaces'

interface LevelMenuProps {
	levelMenu: LevelMenu,
	isActive?: boolean
	getNavLinkClass?: any
}

interface AsideProps {
	asideMenu: LevelMenu[],
	show?:boolean,
	user: User,
	userDropdownItems?: any[]
}

//Item of the menu
const MenuItem: React.FC<LevelMenuProps> = ({ levelMenu, isActive, getNavLinkClass }) => (
	<li className={`menu-item ${isActive ? "has-active" : ""} ${levelMenu.isSlim ? "menu-item-slim" : ""} ${levelMenu.childs ? "has-child" : ""} ${getNavLinkClass(levelMenu.link)}`}>
		<Link to={levelMenu.link} className="menu-link">
			{ levelMenu.icon && (<span className={`menu-icon ${levelMenu.icon}`}></span>) } 	
			<span className="menu-text">{ levelMenu.text }</span>
			{ levelMenu.indicator && (<span className={`${levelMenu.indicatorClasses}`}>{levelMenu.indicator}</span>) }
		</Link>
	</li>
)

//First level of the menu
const LevelMenuItem: React.FC<LevelMenuProps> = ({ levelMenu, isActive, getNavLinkClass }) => {
	const [hasOpen, toggle] = useState(false)

	const handleToggle = (e: MouseEvent) => {
		e.preventDefault()
		toggle(!hasOpen)
	}

	return (
		<li className={`menu-item ${isActive && "has-active"} ${levelMenu.isSlim && "menu-item-slim"} ${levelMenu.childs && "has-child"} ${hasOpen && "has-open"} ${getNavLinkClass(levelMenu.link)}`}>
			<Link to={levelMenu.link} onClick={levelMenu.childs && handleToggle} className="menu-link">
				{ levelMenu.icon && (<span className={`menu-icon ${levelMenu.icon}`}></span>) } 	
				<span className="menu-text">{ levelMenu.text }</span>
				{ levelMenu.indicator && (<span className={`${levelMenu.indicatorClasses}`}>{levelMenu.indicator}</span>) }
			</Link>
			{levelMenu.childs && (
				<ul className="menu">
				 { levelMenu.childs.map((levelMenu, index) => <MenuItem key={index} levelMenu={levelMenu} getNavLinkClass={getNavLinkClass} />) }
				</ul>
			)}
		</li>
	)
}

//Header menu
const HeaderMenuItem: React.FC<LevelMenuProps> = ({ levelMenu, isActive  }) => (
	<li className="menu-header">
		{
			levelMenu.indicator && (
				<span className={levelMenu.indicatorClasses}>{levelMenu.indicator}</span>
		)}
		{levelMenu.text}
	</li>
)

//Aside menu for the dashbord
const Aside: React.FC<AsideProps> = ({ asideMenu, show, user, userDropdownItems}) => {
	const [isOpen, setIsOpen] = useState(false)

	const location = useLocation()

	const getNavLinkClass = (path: string) => {
		return location.pathname.split('/')[1] === path.split('/')[1] ? "has-active" : ""
	}

	return (
		<aside className={`app-aside app-aside-expand-md app-aside-light ${show && "show"}`} >
			<div className="aside-content">
				<header className="aside-header d-block d-md-none">
					<button className="btn-account" type="button" onClick={() => setIsOpen(!isOpen)} >
						<span className="user-avatar user-avatar-lg">
          					{
        						user.avatar && (<img src={user.avatar} alt="" />)
				
        					}
        				</span>
       					<span className="account-icon">
          					<span className={`fa ${isOpen ? "fa-caret-up" : "fa-caret-down"} fa-lg"`}></span>
        				</span>
        				<span className="account-summary">
          					<span className="account-name">{user.name}</span>
          					{user.accountDescription && <span className="account-description">{user.accountDescription}</span>}
        				</span>
					</button>
					<Collapse className="dropdown-aside" isOpen={isOpen}>
						<div className="py-3">
							{userDropdownItems}
						</div>
					</Collapse>
				</header>
				<div className="aside-menu overflow-hidden">
					<nav id="stacked-menu" className="stacked-menu stacked-menu-has-collapsible">
						<ul className="menu">
							{
								asideMenu.map((levelMenu, index) => {
									if (levelMenu.type === 'header') {
										return <HeaderMenuItem key={index} levelMenu={levelMenu} />
									}
									
									if (levelMenu.type === 'link') {
										return <LevelMenuItem key={index} levelMenu={levelMenu} getNavLinkClass={getNavLinkClass} />
									}

									return null
								})
							}
						</ul>
					</nav>
				</div>
			</div>
		</aside>
	)
}


export default Aside