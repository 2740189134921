import React, { createElement } from 'react'
import { Link } from 'react-router-dom'

import { TopBarItemRight } from '../partials'

import { User } from '../Interfaces'

import defaultLogo from '../assets/logo.png'


interface BurgerProps {
	toggle?: () => void
}

interface HeaderProps {
	toggle?: () => void,
	logo?: any,
	topBarItemRightComponent?: any,
	user: User,
	headerNavs?: any[],
	userDropdownItems?: any
}

interface TopBarBrandProps {
	logo?: any
}

const Burger: React.FC<BurgerProps> = ({toggle}) => (
	<div className="top-bar-item px-2 d-md-none d-lg-none d-xl-none">
		<button className="hamburger hamburger-squeeze" type="button" onClick={toggle}>
			<span className="hamburger-box">
            	<span className="hamburger-inner"></span>
			</span>
		</button>
	</div>
)

const TopBarBrand: React.FC<TopBarBrandProps> = ({logo}) => (
	<div className="top-bar">
		 <div className="top-bar-brand">
		 	<Link to="/">
				<img src={logo ? logo : defaultLogo} height="28" alt="" />
			</Link>
		</div>
	</div>
)


const Header: React.FC<HeaderProps> = ({ toggle, logo, user, topBarItemRightComponent, headerNavs, userDropdownItems }) => (
	<header className="app-header app-header-dark">
		<div className="top-bar">
			<TopBarBrand logo={logo} />
			<div className="top-bar-list">
				<Burger toggle={toggle} />
				{topBarItemRightComponent ? createElement(topBarItemRightComponent) : <TopBarItemRight user={user} headerNavs={headerNavs} userDropdownItems={userDropdownItems} />}
			</div>
		</div>
	</header>
)

export default Header