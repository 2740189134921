import React, { useState, useEffect } from 'react'
import { AuthClient, UserContext, PrivateRoute } from 'loxamtraining-core'
import * as firebase from 'firebase/app'
import {BrowserRouter, Switch, Route, useHistory} from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { DropdownItem } from 'reactstrap'

import settings from './settings'

import {User, Loading, Login, ForgotPassword, ResetPassword, DefaultLayout, LevelMenu } from '../lib/apptheme'

import {
	Settings,
	PaymentList,
	PaymentAdd,
	PaymentEdit,
    EventList
} from './pages'



import logo from './assets/images/loxam-logo-white-default.svg'


// Initialize Firebase
const firebaseConfig = settings.firebaseConfig
firebase.initializeApp(firebaseConfig)

const asideMenu: LevelMenu[] = [
	{
		id: "payments",
		type: "link",
		text: "Paiements",
		icon: "far fa-credit-card",
		link: "/",
    },
    {
        id: "events",
        type: "link",
        text: "Evénements",
        icon: "fab fa-creative-commons-sampling",
        link: "/events",
        roles: ["admin"]
    }
]


const DropDownItems = () => {
    const history = useHistory()
    return (
        <React.Fragment>
            <DropdownItem onClick={() => history.push('/settings')}>
                <span className="dropdown-icon oi oi-cog"></span> Paramètres
            </DropdownItem>
        </React.Fragment>
    )
}

const App: React.FC = () => {
	const [isLoading, setLoading]: [boolean, any]= useState(true)
	const [user, setUser]: [any, any] = useState(null)
    const [filteredAsideMenu, setFilteredAsideMenu]: [any, any] = useState([])

	const authClient = new AuthClient()


	useEffect(() => {
        //Get the current user
        authClient.onCurrentUser((user: any) => {
            setUser(user)

            setFilteredAsideMenu(
                asideMenu.filter(menu => {
                    if (user && menu.roles) {
                        return menu.roles.map(role => user.roles[role]).filter(role => role === false).length === 0
                    } else {
                        return true
                    }
                })
            )

            setLoading(false)
        })

        console.log('set current')
    }, [])

    const handleLogin = (email: string, password: string) => {
        //Login the user
        return authClient.login(email, password).then((userCredential: any) => {
            //Get user info and set set user before redirection
            return authClient.getUserInfo(userCredential.user.uid).then((user: any) => {
                return setUser(user)
            })    
        })
    }

    const handleForgotPassword = (email: string) => {
        return authClient.sendPasswordResetEmail(email)
    }

    const handleResetPassword = (code:string, password: string) => {
        return authClient.resetPassword(code, password)
    }

    const handleVerifyPasswordCode = (code:string) => {
        return authClient.verifyPasswordResetCode(code)
    }

    let userProfil: User = {
        name: user && user.firstName
    }

	return (
        isLoading ? (
            <Loading />
        ) : (
            <BrowserRouter>
                <UserContext.Provider value={user}>
                        <Switch>
                            <Route exact path="/login">
                                <Login handleLogin={handleLogin} logo={logo} />
                            </Route>
                            <Route exact path="/forgot-password">
                                <ForgotPassword handleForgotPassword={handleForgotPassword} logo={logo} />
                            </Route>
                             <Route exact path="/reset-password">
                                <ResetPassword handleResetPassword={handleResetPassword} handleVerifyPasswordCode={handleVerifyPasswordCode} logo={logo} />
                            </Route>
                            <PrivateRoute path="/" isAuthenticated={user ? true : false}>
                            	<ToastProvider placement="bottom-right" autoDismiss>
                            		<DefaultLayout asideMenu={filteredAsideMenu} user={userProfil} logo={logo} userDropdownItems={<DropDownItems />}>
	                                    <Switch>
	                                        <Route exact path="/" component={PaymentList} />
	                                        <Route exact path="/payments/add" component={PaymentAdd} />
	                                        <Route path="/payments/:id" component={PaymentEdit} />
                                            <Route path="/events" component={EventList} />
	                                        <Route path="/settings" component={Settings} />
	                                    </Switch>
                                	</DefaultLayout>
                            	</ToastProvider>
                            </PrivateRoute>
                        </Switch>
                </UserContext.Provider>
            </BrowserRouter>
        )
  	)	
}


export default App