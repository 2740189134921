import React from 'react'
import { Table as TableStrap } from 'reactstrap'
import { useHistory } from 'react-router-dom'

interface TableProps {
	data: any,
	isLoading?: any,
	hover?: {
		path: string
	}
}


const Table: React.FC<TableProps> = (props) => {
	const history = useHistory()

	const handleRowClick = (id: string) => {
		if (props.hover) {
			history.push(`${props.hover.path}/${id}`)
		}
	}

	const hover: boolean = props.hover ? true : false

	return (
		<div className="table-responsive">
			<TableStrap hover={hover}>
				<thead>
					<tr>
						{
							React.Children.map(props.children, (child: any) => (
								<th style={child.props.action && {width:"100px", minWidth:"100px"}}>{child.props.label ? child.props.label : " "}</th>
							))
						}
					</tr>
				</thead>
				<tbody>
					{
						!props.isLoading ? props.data.length > 0 ? 
							props.data.map((item: any, index: number) => {
								return hover ? (
									<tr key={index} onClick={() => handleRowClick(item.id)}>
										{
											React.Children.map(props.children, (child: any, index: number) => (
												<td key={index} className={`align-middle ${child.props.action && "text-right"}`}>
													{React.cloneElement(child, {data: child.props.name ? item[child.props.name] : item})}
												</td>
											))
										}
									</tr>
								) : (
									<tr key={index}>
										{
											React.Children.map(props.children, (child: any, index: number) => (
												<td key={index} className={`align-middle ${child.props.action && "text-right"}`}>
													{React.cloneElement(child, {data: child.props.name ? item[child.props.name] : item})}
												</td>
											))
										}
									</tr>
								)
								
							}) : (
								<tr>
									<td colSpan={React.Children.count(props.children)}>Pas encore d'élément</td>
								</tr>
							) : (
								<tr>
									<td colSpan={React.Children.count(props.children)}>Chargement en cours...</td>
								</tr>
							)
					}
				</tbody>
			</TableStrap>
		</div>
	)
}


Table.defaultProps = {
	isLoading: false
}


export default Table