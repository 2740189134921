import React, { useState } from 'react'
import { DropdownItem } from 'reactstrap'

import { User } from '../Interfaces'

import { UserDropdown } from '../partials'


interface TopBarItemRightProps {
	user: User,
	headerNavs?: any[],
	userDropdownItems?: any
}


const TopBarItemRight: React.FC<TopBarItemRightProps> = ({ user, headerNavs, userDropdownItems }) => {
	const [dropdownOpen, toggle] = useState(false)

	return (
		<div className="top-bar-item top-bar-item-right px-0 d-none d-sm-flex">
			<ul className="header-nav nav">
				{headerNavs}
			</ul>
			<UserDropdown user={user} isOpen={dropdownOpen} toggle={() => toggle(!dropdownOpen)}>
				<DropdownItem header disabled className="d-none d-md-block d-lg-none">{user.name}</DropdownItem>
				{userDropdownItems}
			</UserDropdown>
		</div>
	)
}

export default TopBarItemRight
