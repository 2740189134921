import React from 'react'
import * as firebase from 'firebase/app'
import 'firebase/functions'
import { Card, CardHeader, CardBody, Button, Modal as ModalStrap, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useParams, Link } from 'react-router-dom'
import { useAsyncData, useAsyncData2, FirestoreClient, useModal } from 'loxamtraining-core'
import { Form, CheckboxField, TextField, EmailField } from 'loxamtraining-form'
import { EditData } from 'loxamtraining-data'


import { Table, FormSet } from '../../lib/apptheme'

const roles: any = {
	admin: "Administrateur",
	agent: "Responsable d'agence"
}


/**
 * Display firstName, lastName, email of the user
 */
const NameData = (props: any) => {	
	return (
		props.data ? (
			<React.Fragment>
				<span>{props.data.firstName} {props.data.lastName}</span><br />
				<span className="text-muted">{props.data.email}</span>
			</React.Fragment>
		) : <React.Fragment>-</React.Fragment>
	)
}


/**
 * List role of the user
 */
const RolesData = (props: any) => (
	<React.Fragment>
		{
			props.data ? (
				Object.keys(props.data).filter(key => props.data[key] === true).map((key: string) => roles[key] ? roles[key] : "").join(", ")
			) : "-"
		}
	</React.Fragment>
)

export const SettingsMembers: React.FC = () => {
	const [isLoading, data] = useAsyncData('/users')


	return (
		<Card>
			<CardHeader>
				<div className="d-flex justify-content-between align-items-center">
					<span className="mr-sm-auto">Membres</span>
					<Link to="/settings/members/add" className="btn btn-primary btn-sm">Ajouter un membre</Link>
				</div>
			</CardHeader>
			<CardBody>
				<Table data={data} isLoading={isLoading}>
					<NameData label="Nom" />
					<RolesData name="roles" label="Roles" />
					<EditData path="/settings/members" name="id" />
				</Table>
			</CardBody>
		</Card>
	)
}

export const SettingMembersEditModal: React.FC = () => {
	const {modal, toggle} = useModal()

	const { id } = useParams()

	const [isLoading, data, setData] = useAsyncData2(`/users/${id}`)

	const handleSubmit = (values: any, actions: any) => {
		const firestoreClient = new FirestoreClient()

		return firestoreClient.update(`/users/${id}`, values).then((data: any) => {
			setData(data)
			toggle()
		})
	}

	return (
		<ModalStrap isOpen={modal} toggle={toggle}>
			<ModalHeader toggle={toggle}>Modification du membre</ModalHeader>
			<FormSet data={data} handleSubmit={handleSubmit} isLoading={isLoading} hideSubmitButton={true}>
				<ModalBody>
					<fieldset>
						<legend>Informations</legend>
						<TextField name="firstName" label="Prénom" required />
						<TextField name="lastName" label="Nom" required />
						<EmailField name="email" label="Email" required />
					</fieldset>
					<fieldset>
						<TextField name="store" label="Code de l'agence" />
					</fieldset>
					<fieldset>
						<legend>Roles</legend>
						<CheckboxField name="roles.admin" label="Administrateur" />
						<CheckboxField name="roles.agent" label="Responsable d'agence" />
					</fieldset>
				</ModalBody>
				<ModalFooter>
					<Button type="submit" color="primary">Enregistrer</Button>
				</ModalFooter>
			</FormSet>
		</ModalStrap>
	)
}

export const SettingMembersAddModal: React.FC = () => {
	//const firestoreClient = new FirestoreClient()
	const {modal, toggle} = useModal()

	const isLoading = false


	const handleSubmit = async (values: any, actions: any) => {
		
		const createUser = firebase.app().functions("europe-west1").httpsCallable('createUser')
		await createUser(values)
		toggle()
		
	}

	const initialValues = {
		firstName: "",
		lastName: "",
		email: "",
		store: "",
		roles: {
			admin: false,
			agent: false
		}
	}

	return (
		<ModalStrap isOpen={modal} toggle={toggle}>
			<ModalHeader toggle={toggle}>Inviter un membre</ModalHeader>
			<FormSet data={initialValues} handleSubmit={handleSubmit} isLoading={isLoading} hideSubmitButton={true}>
				<ModalBody>
					<fieldset>
						<legend>Informations</legend>
						<TextField name="firstName" label="Prénom" required />
						<TextField name="lastName" label="Nom" required />
						<EmailField name="email" label="Email" required />
					</fieldset>
					<fieldset>
						<TextField name="store" label="Code de l'agence" />
					</fieldset>
					<fieldset>
						<legend>Roles</legend>
						<CheckboxField name="roles.admin" label="Administrateur" />
						<CheckboxField name="roles.agent" label="Agent" />
					</fieldset>
				</ModalBody>
				<ModalFooter>
					<Button type="submit" color="primary">Enregistrer</Button>
				</ModalFooter>
			</FormSet>
		</ModalStrap>
	)
}