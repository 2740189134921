import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    children: any;
    isAuthenticated: boolean;
}


const PrivateRoute = (props: PrivateRouteProps) => {

    const { children, isAuthenticated, ...rest } = props

    return (
        <Route
            {...rest}
            render={({ location }) =>
                    isAuthenticated ? (
                      children
                ) : (
                    <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: location }
                    }}
                  />
                )
            }
        />
    )
}


export default PrivateRoute