import React from 'react'
import * as yup from 'yup'
import ReactStrapComponent from './ReactStrapComponent'
import Field from '../Field'


const validationSchema = yup.string().email("L'adresse email n'est pas valide.")



const EmailField = (props:any) => <Field type="email" component={ReactStrapComponent} validationSchema={validationSchema} {...props} />



export default EmailField