import React, {useRef, useLayoutEffect, useEffect, useState} from 'react'
import { FieldProps as FieldFormikProps} from 'formik'
import { FormGroup, Label, Input, InputProps, FormText, InputGroup, InputGroupAddon } from 'reactstrap'
import _ from 'lodash'

import NumberFormat from 'react-number-format'

interface FieldProps extends FieldFormikProps, InputProps {
	label?: string,
	helptext?: string,
	name: string,
	form: any
	options?: any[],
	focus?: boolean
}

const useKeyPress = (targetKey: any) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  // If pressed key is our target key then set to true
  function downHandler({ key } : {key: any}) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key } : {key: any}) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}


const displayAmount = (amount: string) => amount

const useRunAfterUpdate = () => {
	const afterPaintRef: any = useRef(null)

	useLayoutEffect(() => {
		if (afterPaintRef.current) {
			afterPaintRef.current()
			afterPaintRef.current = null
		}
	})

	const runAfterUpdate = (fn:any) => (afterPaintRef.current = fn)


	return runAfterUpdate
}

const separator: string = "."

const ReactStrapAmountComponent: React.FC<FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  focus,
  ...props
}) => {
	const error = _.get(errors, field.name)
	const isTouched = _.get(touched, field.name)

	const backspaceKey = useKeyPress('Backspace')
	const runAfterUpdate = useRunAfterUpdate()
	const inputRef: any = useRef(null)

	const handleFocus = (event: any) => event.target.select()

	const renderError = () => {
		return (isTouched && error)
	}

	useEffect(() => {
		if (focus) {
			inputRef.current.focus()
		}
	})

	const handleChange = (event: any) => {
		const input = event.target
		let value = input.value.toString()
		let cursor = input.selectionStart

		if (value === "") {
			value = "0" + separator + "00"
			cursor += 1
		}

		//si dernier caractere "," ou pas de "," alors on met des decimals à 0
		if (value.slice(-1) === separator || (value.indexOf(separator) === -1 && !backspaceKey)) {
			if (separator === ",") {
				value = parseInt(value).toFixed(2).replace(".", ",")
			} 

			if (separator === ".") {
				value = parseInt(value).toFixed(2)
			}
		}

		//Si il y a un separator
		if (value.indexOf(separator) > -1) {
			const decimal = value.split(separator)

			//plussieur separator on supprime celui qui vien de s'ajouter
			if (decimal[1].length > 2) {
				value = value.slice(0, -1)
			}

			//un seul decimal on met un 0 à la fin
			if (decimal[1].length === 1) {
				value = value.concat("0")
			}


			//pas de chiffre avant la virgule on met un 0, le cursor est déplacé à droite
			if (decimal[0].length === 0) {
				value = "0".concat(value)
				cursor += 1
			}
		}

		//on supprier le 0 si c'est le premier caractère le cursor est déplacé à gauche
		if ((separator === "," && /^0\d+(?:[\,]00)$/.test(value)) || (separator === "." && /^0\d+(?:[\.]00)$/.test(value))) {
			value = value.substr(1)
			cursor -= 1
		}

        const regex = /^(\d+(?:[\.\,]\d{2}))$/

        //la valeur est un chiffre à 2 decimal on met à jour
        if ((separator === "," && /^(\d+(?:[\,]\d{2}))$/.test(value.toString())) || (separator === "." && /^(\d+(?:[\.]\d{2}))$/.test(value.toString()))) { 	
        	setFieldValue(field.name, Number((value.replace(",",".")) * 100).toFixed(0))
        } else {
         	//sinon on laisse l'ancienne
        	setFieldValue(field.name, field.value)
        }

        //on met à jour le cursor
        runAfterUpdate(() => {
    		input.selectionStart = cursor
    		input.selectionEnd = cursor
    	})
	}

	const renderValue = () => {
		let value: string = (field.value / 100).toFixed(2)
		return value
	}

	return (
	  	<FormGroup>
			{props.label && <Label for={props.id}>{props.label} {props.required && "*"}</Label>}
			<InputGroup className={renderError() ? "is-invalid" : ""}>
				<InputGroupAddon addonType="prepend">€</InputGroupAddon>
				<Input innerRef={inputRef} name={field.name} value={renderValue()} onBlur={field.onBlur} onChange={handleChange} onFocus={handleFocus} {...props} className={renderError() ? "is-invalid" : ""} />
			</InputGroup>
			{renderError() ? <FormText color="error" className="invalid-feedback">{error}{console.log(error)} </FormText> : null}
			{props.helptext && <FormText>{props.helptext}</FormText>}
		</FormGroup>
	)
}


export default ReactStrapAmountComponent