import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { AuthClient } from 'loxamtraining-core'
import { Form, PasswordField } from 'loxamtraining-form'


const SettingsSecurity: React.FC = () => {	
	const authClient = new AuthClient()

	const handleSubmit = (values: any, actions: any) => {
		authClient.updatePassword(values.password).then(() => {
			actions.resetForm()
			actions.setSubmitting(false)
		}).catch(error => {
			error && error.message ? (
				actions.setStatus(error.message)
			) : (
				actions.setStatus("Erreur lors de la demande de réinitialisation de mot de passe")
			)
			
			actions.setSubmitting(false)
		})
	}

	//Check the password and confirmPassword are identic
	const validate = (values: any) => {
		const errors: any = {}

		if (values.password !== values.confirmPassword) {
			errors.confirmPassword = "La confirmation du mot de passe n'est pas identidentique au mot de passe"
		}

		return errors
	}

	return (
		<Card>
			<CardHeader>Modification du mot de passe</CardHeader>
			<CardBody>
				<Form validate={validate} onSubmit={handleSubmit} initialValues={{password: "", confirmPassword: ""}} buttonName="Mettre à jour le mot de passe">
					<PasswordField name="password" label="Nouveau mot de passe" required />
					<PasswordField name="confirmPassword" label="Confirmation du nouveau mot de passe" required />
				</Form>
			</CardBody>
		</Card>
	)
}

export default SettingsSecurity