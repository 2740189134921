import React from 'react'
import { Link, useHistory} from 'react-router-dom'

import { Form, PasswordField, EmailField } from 'loxamtraining-form'

import defaultLogo from '../assets/images/brand-logo.svg'


interface LoginProps {
	handleLogin: any,
	logo?: any
}


const Login: React.FC<LoginProps> = ({handleLogin, logo}) => {
	const history = useHistory()

	const handleSubmit = (values: any, actions: any) => {
		handleLogin(values.email, values.password).then(() => {
			history.push('/')
		}).catch((error: any) => {
			error && error.message ? (
				actions.setStatus(error.message)
			) : (
				actions.setStatus("Erreur lors de la demande de réinitialisation de mot de passe")
			)
			
			actions.setSubmitting(false)
		})
	}

	return (
		<main className="auth">
			<header id="auth-header" className="auth-header" >
				<h1>
					<img src={logo ? logo : defaultLogo} height="28" alt="" />
					<span className="sr-only">Sign In</span>
				</h1>
			</header>
			<div className="auth-form">
				<Form onSubmit={handleSubmit} initialValues={{email: "", password: ""}} buttonName="Se connecter" buttonClassName="btn btn-lg btn-primary btn-block">
					<EmailField name="email" label="Email" required />
					<PasswordField name="password" label="Mot de passe" required />
				</Form>
				<div className="text-center pt-3">
					<Link to="/forgot-password" className="link">Mot de passe oublié ?</Link>
				</div>
			</div>
		</main>
	)
}

export default Login