import * as firebase from 'firebase/app'
import 'firebase/firestore'

/**
 * Client to use Firebase Firestore
 *
 */
class FirestoreClient {
	db: any

	constructor() {
		this.db = firebase.firestore()
	}


	/**
	 * Return doc formated
	 */
	getDoc(docSnap: any) {
		return Object.assign({id: docSnap.id}, docSnap.data())
	}

	/**
 	 * Get all docs in a collection
	 * @params {string} path - path of the collection
	*/
	getAll(path: string): Promise<any> {
		return this.db.collection(path).get().then((querySnap: any) => {
			if (querySnap.constructor.name === "DocumentSnapshot" && querySnap.exists === false) {
				return []  
			} else {
				return querySnap.docs.map((docSnap: any) => this.getDoc(docSnap))	
			}
		})
	}


	/**
	 * Get a doc in a collection
	 * @params {string} path - path of the doc
	*/
	get(path: string): Promise<any> {
		return this.db.doc(path).get().then((docSnap: any) => {
			return docSnap.exists ? this.getDoc(docSnap) : null
		})
	}


	/**
	 * Add a document
	 * @params {string} path - path of the doc
	 * @params {object} doc - new data
	*/
	add(path: string, doc: any): Promise<any> {	
		return this.db.collection(path).add(doc).then((docRef: any) => {
			return docRef.id
		})
	}


	/**
	 * Update a document
	 * @params {string} path - path of the doc
	 * @params {object} doc - new data
	*/
	update(path: string, doc: any): Promise<any> {
		delete doc.id

		return this.db.doc(path).update(doc).then(() => {
			return this.get(path)
		})
	}
}

export default FirestoreClient