import React, { useState, useEffect } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import moment from 'moment'
import { Link, Switch, Route } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { FirestoreClient, useAsyncData, useAsyncData2, useModal, useUserContext } from 'loxamtraining-core'
import { TextData, EditData, UnixDateData } from 'loxamtraining-data'
import { Card, CardBody, CardHeader, Badge, Tooltip, Row, Col, Button, Modal as ModalStrap, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {Field, CheckboxField} from 'loxamtraining-form'
import {useFormikContext} from 'formik'
import Skeleton from 'react-loading-skeleton'
import ReactJson from 'react-json-view'
import * as yup from 'yup'


import { Page, PageSection, PageTitleBar, Table, PageAdd, PageEdit, FormSet } from '../../lib/apptheme'
import ReactStrapComponent from '../../lib/ReactStrapComponent'
import ReactStrapAmountComponent from '../../lib/ReactStrapAmountComponent'

import settings from '../settings'


const TextField = (props:any) => <Field type="text" component={ReactStrapComponent} {...props} />

const SelectField = (props:any) => <Field type="select" component={ReactStrapComponent} {...props} />

const AmountField = (props:any) => <Field type="text" component={ReactStrapAmountComponent} {...props} />


const paymentTypes = [
	{value: "deposit", label: "Caution - Le montant n'est pas capturé", resume: "Caution"},
	{value: "capture", label: "Capture - Le montant est capturé immédiatement", resume: "Capture"}
]

const statusOptions = [
	{value: "initiated", label: "Initialisation", description: "Initialisation de la demande de paiement."},
	{value: "created", label: "Incomplet", description: "Le client n'a pas encore indiqué de moyen de paiement."},
	{value: "succeeded", label: "Réussi", description: "Ce paiement est terminé."},
	{value: "requires_capture", label: "Non capturé", description: "Paiement autorisé, mais pas encore capturé."},
	{value: "error", label: "Erreur", description: "Veuillez contacter le support"},
	{value: "canceled", label: "Annulé", description: "Le paiement est annulé"},
	{value: "failed", label: "Echoué", description: "L'ajout du moyen de paiement a échoué"},
	
]

const displayAmount = (amount: any) => {

    let price: string = (amount / 100).toFixed(2);
    
    let numberFormat = new Intl.NumberFormat(['fr-FR'], {
      style: 'currency',
      currency: "EUR",
      currencyDisplay: 'symbol',
    })

    return numberFormat.format(parseFloat(price))
  }

const emailValidation = yup.string().email("Veuillez renseigner une adresse email valide")

const phoneValidation = yup.string().matches(/^((\+)33|0)[1-9](\d{2}){4}$/, "Le numéro de téléphone n'est pas valide")

let toolCount = 0

const ToolItemBadge = (props: any) =>{
	const [id, setId]: [any, any] = useState()
	const [tooltipOpen, setTooltipOpen] = useState(false)

	const toggle = () => setTooltipOpen(!tooltipOpen)

	useEffect(() => {
		const id: string = `status-${toolCount.toString()}`

		setId(id)

		toolCount += 1
	}, [])
	

	return (
		id ? (
			<span>
				<Badge color={props.color} id={id}>{props.status} <span className={props.icon}></span></Badge>
				<Tooltip
			        placement="top"
			        isOpen={tooltipOpen}
			        target={id}
			        toggle={toggle}
			      >
		        	{props.message}
				</Tooltip>
			</span>
		) : <span></span>
		
	)
}

const renderStatus = (status: string) => {
	switch (status) {
		case 'initiated':
			return (
				<ToolItemBadge
					status="Initialisation"
					icon="oi oi-media-record ml-2 pulse"
					color="light"
			        message="Initialisation de la demande de paiement."
			      />
			)
		case 'created':
			return (
				<ToolItemBadge
					status="Incomplet"
					icon="oi oi-clock ml-2"
					color="info"
			        message="Le client n'a pas encore indiqué de moyen de paiement."
			      />
			)
		case 'succeeded':
			return (
				<ToolItemBadge
					status="Réussi"
					icon="oi oi-check ml-2"
					color="success"
			        message="Ce paiement est terminé."
			      />
			)
		case 'saved':
			return (
				<ToolItemBadge
					status="Enregistré"
					icon="fa fa-save ml-2"
					color="success"
			        message="Ce paiement est terminé et renseigné dans RentalMan"
			      />
			)
		case 'requires_capture':
			return (
				<ToolItemBadge
					status="Non capturé"
					icon="oi oi-clock ml-2"
					color="warning"
			        message="Paiement autorisé, mais pas encore capturé."
			      />
			)
		case 'canceled':
			return (
				<ToolItemBadge
					status="Annulé"
					icon="oi oi-delete ml-2"
					color="dark"
			        message="Le paiment est annulé"
			      />
			)
		case 'failed':
			return (
				<ToolItemBadge
					status="Echoué"
					icon="oi oi-circle-x ml-2"
					color="secondary"
			        message="L'ajout du moyen de paiement a échoué"
			      />
			)
		default:
			return (
				<ToolItemBadge
					status="Erreur"
					icon="oi oi-question-mark ml-2"
					color="danger"
			        message="Le statut n'est pas connu. Veuillez contacter le support"
			      />
			)

	}
}

const UnixDateTimeData = (props: any) => <React.Fragment>{props.data ? moment.unix(props.data.seconds).format("DD/MM/YYYY à HH:mm") : "-"}</React.Fragment>

const StatusData = (props: any) => {
	return <React.Fragment>{props.data ? renderStatus(props.data) : "-"}</React.Fragment>
}


const ContractData = (props: any) => {
	return <React.Fragment>{props.data ? props.data.order.contractId : "-"}</React.Fragment>
}

const CustomerCodeData = (props: any) => {
	return <React.Fragment>{props.data ? props.data.customer.customerCode : "-"}</React.Fragment>
}

const PaymentTypeData = (props: any) => {
	return <React.Fragment>{props.data ? paymentTypes.filter(item => item.value === props.data)[0].resume : "-"}</React.Fragment>
}

const AmountData = (props: any) => {
	return (
		<React.Fragment>
		{
			props.data ? (
				<span>
					<strong>{displayAmount(props.data.paymentIntent.amount)}</strong><br />
					{
						props.data.status === "succeeded" && (
							<small>{displayAmount(props.data.paymentIntent.amount_received)} capturé</small>)
						
					}
			</span>
			) : "-"
		}
		</React.Fragment>
	)
}


const getDoc = (docSnap: any) => {
	return Object.assign({id: docSnap.id}, docSnap.data())
}

const usePaginateCollectionFirestore = (ref: any, limit: number) => {
	const [isLoading, setLoading] = useState(true)
	const [data, setData]: [any, any] = useState([])
	const [last, setLast]: [any, any] = useState(null)
	const [count, setCount]: [any, any] = useState(limit)

	let queryRef = ref.limit(limit)

	const fetchData = async () => {
		if (last) {
			queryRef = queryRef.startAfter(last.data().createdAt)
		}

		const querySnap = await queryRef.get()

		setCount(querySnap.docs.length)
		setLast(querySnap.docs[querySnap.docs.length - 1])
		setData(data.concat(querySnap.docs.map((docSnap: any) => getDoc(docSnap))))
		setLoading(false)
	}

	return [data, isLoading, count, fetchData]
}

/*
 * List Payment view
 */
export const PaymentList = () => {
	const limit: number = 50
	const user: any | null = useUserContext()
	let ref = firebase.firestore().collection('/payments').orderBy('createdAt', 'desc')
	ref = (user && user.roles.admin) ? ref : ref.where('store', '==', user.store)
	const [data, isLoading, count, fetchData] = usePaginateCollectionFirestore(ref, limit)

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Page>
			<PageTitleBar>
				<div className="d-flex justify-content-between">
					<h1 className="page-title mr-sm-auto">Paiements</h1>
					<div className="btn-toolbar">
						<Link to="/payments/add" className="btn btn-secondary"><i className="fa fa-plus"></i> Nouveau paiement</Link>
					</div>
				</div>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Table data={data} isLoading={isLoading} hover={{path:"/payments"}}>
							<AmountData label="Montant" />
							<StatusData name="status" label="Statut" />
							<TextData name="id" label="Id de paiement" />
							<CustomerCodeData label="Code client" />
							<ContractData label="N° contrat / bon de vente" />
							<TextData name="store" label="Agence" />
							<PaymentTypeData name="type" label="Type de paiement" />
							<UnixDateTimeData name="createdAt" label="Date" />
							<EditData path="/payments" name="id" />
						</Table>
						{
							count === limit && isLoading === false && <Button type="button" onClick={() => fetchData()}>Suivant</Button>
						}						
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}


const ContratFieldSet = () => {
	const { values } : { values: any } = useFormikContext()

	const validationSchema: any = values.type === "capture" ? yup.string().length(9, "Le champ doit contenir exactement 9 caractères") : yup.string()

	const helptext: string = values.type == "capture" ? "Pour faire un capture vous devez obligatoirement mettre un numéro de contrat ou de bon de vente. Faites une caution si vous n'avez pas cette information pour le moment et capturez le montant dès que vous avez l'information"
		: "Référence permettant d'associer le paiement à une location ou à une vente. Une autre référence que le numéro de contrat ou le bon de vente peut être renseigné pour créer la caution. Par contre l'un des deux sera demandé au moment de la capture"
	return (
		<fieldset className="mb-3">
			<legend>Contrat</legend>
			<TextField name="customer.customerCode" label="Code client" helptext="Numéro du client dans RentalMan" required />
			<TextField name="order.contractId" label="N° contrat / bon de vente" helptext={helptext} validationSchema={validationSchema} required />
		</fieldset>
	)
}


/*
 * Add payment view
 */
export const PaymentAdd = () => {
	const user: any | null = useUserContext()

	const initialValues = {
		customer: {
			customerCode: "",
			email: ""
		},
		order: {
			contractId: ""
		},
		paymentIntent: {
			amount: "",
		},
		status: "initiated",
		store: user && user.store ? user.store : "",
		author: user.id
	}

	//const contractIdValidation = yup.string().length(9, "Le champ doit contenir exactement 9 caractères")

	//const amountValidation = yup.string().matches(/^([1-5]\d+(?:[\.\,]\d{2}))$/, "Le montant doit être positif")

	const amountValidation = yup.number().integer("Le montant n'est pas correct").positive("Le montant doit être positif")

	return (
		<Row>
			<Col md={{size: "9", offset: "1"}}>
				<PageAdd path="/payments" title="Nouveau paiement" initialValues={initialValues}>
					<fieldset className="mb-3">
						<legend>Paiement</legend>
						<SelectField name="type" label="Type de paiement" required>
							<option value=""></option>
							{
								paymentTypes.map((item, index) => <option value={item.value} key={index}>{item.label}</option>)
							}
						</SelectField>
						<AmountField name="paymentIntent.amount" label="Montant" required validationSchema={amountValidation} />
					</fieldset>
					<ContratFieldSet />
					<fieldset className="mb-3">
						<legend>Notification</legend>
						<TextField name="customer.email" label="Email" required helptext="Email pour evoyer le lien de paiement au client" validationSchema={emailValidation}/>
					</fieldset>
				</PageAdd>
			</Col>
		</Row>
	)
}			
		

export const CaptureModal: React.FC = () => {
	const {modal, toggle} = useModal()

	const { id } = useParams()

	const [isLoading, data, setData] = useAsyncData2(`/payments/${id}`)

	const handleSubmit = async (values: any, actions: any) => {

		//1.update meta data
		const updateMetaData = firebase.app().functions("europe-west1").httpsCallable('updateMetaData')
		await updateMetaData({
			phone: values.phone,
			email: values.email,
			contractId: values.contractId,
			customerCode: values.customerCode,
			paymentId: values.paymentId,
		})

		//2.capture the amount
		const capturePayment = firebase.app().functions("europe-west1").httpsCallable('capturePayment')
		await capturePayment({
			amount_to_capture: values.amount_to_capture,
			paymentIntentId: values.paymentIntentId,
			paymentId: values.paymentId
		})
		toggle()
		
	}

	const initalValues = data.paymentIntent ? {
		phone: data.customer.phone,
		email: data.customer.email,
		contractId: data.order.contractId,
		customerCode: data.customer.customerCode,
		paymentId: id,
		amount_to_capture: data.paymentIntent.amount,
		paymentIntentId: data.paymentIntent.id,
	} : null

	const amountValidation = data && data.paymentIntent && data.paymentIntent.amount ? 
		yup.number().positive("Le montant doit être positif").max(data.paymentIntent.amount, "Le montant ne peut pas dépasser le montant de la caution") : yup.number().positive("Le montant doit être positif")

	const contractIdValidation = yup.string().length(9, "Le champ doit contenir exactement 9 caractères")
	return (
		<React.Fragment>
			<ModalStrap isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Capturer le montant</ModalHeader>
				<FormSet data={initalValues} handleSubmit={handleSubmit} isLoading={isLoading} hideSubmitButton={true}>
					<ModalBody>
						<fieldset>
							<AmountField name="amount_to_capture" label="Montant à capturer" helptext="Le montant est immediatement débité" required validationSchema={amountValidation} />
							<TextField name="contractId" label="N° contrat / bon de vente" helptext={"Pour faire un capture vous devez obligatoirement mettre un numéro de contrat ou de bon de vente"} validationSchema={contractIdValidation} required />
						</fieldset>
					</ModalBody>
					<ModalFooter>
						<Button type="submit" color="primary">Capturer</Button>
					</ModalFooter>
				</FormSet>
			</ModalStrap>
		</React.Fragment>
	)
}

export const CancelModal: React.FC = () => {
	const {modal, toggle} = useModal()

	const { id } = useParams()

	const handleSubmit = async (values: any, actions: any) => {
		if (values.confirm === true) {
			const cancelPayment = firebase.app().functions("europe-west1").httpsCallable('cancelPayment')
			await cancelPayment({paymentId: values.paymentId})
			toggle()
		} else {
			actions.setStatus("Veuillez confirmer l'annulation pour annuler le paiement")
			actions.setSubmitting(false)
		}
	}

	const initalValues = {
		paymentId: id,
		conform: false
	}

	return (
		<ModalStrap isOpen={modal} toggle={toggle}>
			<ModalHeader toggle={toggle}>Annuler le paiement</ModalHeader>
			<FormSet data={initalValues} handleSubmit={handleSubmit} isLoading={false} hideSubmitButton={true}>
			<ModalBody>
				 <CheckboxField name="confirm" label="Je souhaite annuler ce paiement" required/>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" color="primary">Valider</Button>
			</ModalFooter>
			</FormSet>
		</ModalStrap>
	)
}


export const UpdateMetaDataModal: React.FC = () => {
	const {modal, toggle} = useModal()

	const { id } = useParams()

	const [isLoading, data, setData] = useAsyncData2(`/payments/${id}`)

	const handleSubmit = async (values: any, actions: any) => {
		const updateMetaData = firebase.app().functions("europe-west1").httpsCallable('updateMetaData')
		await updateMetaData(values)
		toggle()
	}

	const initalValues = data.paymentIntent ? {
		phone: data.customer.phone,
		email: data.customer.email,
		contractId: data.order.contractId,
		customerCode: data.customer.customerCode,
		paymentId: id
	} : null

	return (
		<ModalStrap isOpen={modal} toggle={toggle}>
			<ModalHeader toggle={toggle}>Modifier les informations</ModalHeader>
			<FormSet data={initalValues} handleSubmit={handleSubmit} isLoading={isLoading} hideSubmitButton={true}>
				<ModalBody>
					<fieldset>
						<fieldset className="mb-3">
							<legend>Contrat</legend>
							<TextField name="customerCode" label="Code client" helptext="Numéro du client dans RentalMan" required />
							<TextField name="contractId" label="N° contrat / bon de vente" helptext="Référence permettant d'associer le paiement à une location ou à une vente. Le numéro de contrat ou le numéro de facture" required />
						</fieldset>
						<fieldset className="mb-3">
							<legend>Notification</legend>
							<TextField name="email" type="email" label="Email" required helptext="Email du client" validationSchema={emailValidation} />
							<TextField name="phone" label="Téléphone" helptext="Numéro de téléphone du client. Exemple de numéros valident : 0610230943 ou +33610230943" validationSchema={phoneValidation} />
						</fieldset>
					</fieldset>
				</ModalBody>
				<ModalFooter>
					<Button type="submit" color="primary">Modifier</Button>
				</ModalFooter>
			</FormSet>
		</ModalStrap>
	)
}


export const SendSMSNotificationModal: React.FC = () => {
	const firestoreClient = new FirestoreClient()

	const {modal, toggle} = useModal()

	const { id } = useParams()

	const [isLoading, data, setData] = useAsyncData2(`/payments/${id}`)

	const handleSubmit = async (values: any, actions: any) => {
		const customer = Object.assign({phone: values.phone}, data.customer)
		await firestoreClient.update(`payments/${id}`, {customer})

		const sendSMSNotification = firebase.app().functions("europe-west1").httpsCallable('sendSMSNotification')
		await sendSMSNotification({paymentId: id})
		toggle()
	}

	const initalValues = data.paymentIntent ? {
		phone: data.customer.phone,
		paymentId: id
	} : null

	return (
		<ModalStrap isOpen={modal} toggle={toggle}>
			<ModalHeader toggle={toggle}>Renvoyer le lien de paiement</ModalHeader>
			<FormSet data={initalValues} handleSubmit={handleSubmit} isLoading={isLoading} hideSubmitButton={true}>
				<ModalBody>
					<TextField name="phone" label="Téléphone" helptext="Numéro de téléphone du client. Exemple de numéros valident : 0610230943 ou +33610230943" required validationSchema={phoneValidation} />
				</ModalBody>
				<ModalFooter>
					<Button type="submit" color="primary">Envoyer</Button>
				</ModalFooter>
			</FormSet>
		</ModalStrap>
	)
}



export const PaymentEdit = () => {
	const [isLoading, setLoading] = useState(true)
	const [data, setData] = useState({} as any)
	const [saving, setSaving] = useState(false)

	useEffect(() => {

	}, [])

	const { id } = useParams() 

	const hide: boolean = true

	useEffect(() => {
		if (id) {
			//TODO: add to lib
			firebase.firestore().doc(`/payments/${id}`).onSnapshot((docSnap) => {
				const payment: any = Object.assign({id: docSnap.id}, docSnap.data())
				setData(payment)
				setLoading(false)
			})
		}
	}, [])

	let status
	let paymentType
	if (data) {
		//Get status
		const option = statusOptions.filter(item => item.value === data.status)
		const error = {value: "error", label: "Erreur", description: "Veuillez contacter le support"}
		status = option.length > 0 ?  option[0] : error

		//Get paymentType
		const option2 = paymentTypes.filter(item => item.value === data.type)
		paymentType = option2.length > 0 ? option2[0].label	: '-'
	}

	const savePayment = async () => {
		setSaving(true)
		const cancelPayment = firebase.app().functions("europe-west1").httpsCallable('validatePayment')
		await cancelPayment({paymentId: id})
		setSaving(false)
	}
	

	return (
		<Page>
			<PageTitleBar>
				{
					isLoading ? (
						<h4 className="page-title mr-sm-auto">
							<dt className="col-sm-3"><Skeleton count={1} height={20} /></dt>
						</h4>
						) : (
							<div className="d-flex justify-content-between">
								<h4 className="page-title mr-sm-auto">
									<span className="col-sm-9">{displayAmount(data.paymentIntent.amount)}</span>
									<span className="col-sm-9">{renderStatus(data.status)}</span>
									{
										(data.status === "succeeded" || data.status === "saved") && (
											<small>({displayAmount(data.paymentIntent.amount_received)} capturé)</small>)
									}
								</h4>
								{
									data.status === "succeeded" && (
										<div className="btn-toolbar">
											<span className="mr-2">
												<Button className="btn btn-secondary" onClick={savePayment} disabled={saving}><i className="fa fa-save mr-1"></i> {saving ? "Enregistrement en cours" : "Enregistré"}</Button>
											</span>
										</div>
									)
								}
								
								{
									data.status === "requires_capture" && (
										<div className="btn-toolbar">
											<span className="mr-2">
												<Link to={`/payments/${data.id}/capture`} className="btn btn-secondary" ><i className="fa fa-check mr-1"></i> Capturer</Link>
											</span>
											<span>
												<Link to={`/payments/${data.id}/cancel`} className="btn btn-secondary"><i className="fa fa-undo mr-1"></i> Annuler</Link>
											</span>
										</div>
									)
								}
								{
									data.status === "created" && (
										<div className="btn-toolbar">
											<span className="mr-2">
												<Link to={`/payments/${data.id}/send-sms-notification`} className="btn btn-secondary" ><i className="fas fa-paper-plane"></i> Renvoyer le lien de paiement</Link>
											</span>
											<span>
												<Link to={`/payments/${data.id}/cancel`} className="btn btn-secondary"><i className="fa fa-undo mr-1"></i> Annuler</Link>
											</span>
										</div>
									)
								}
							</div>
						)
				}
				
			</PageTitleBar>
			<PageSection>
				<Card>
					{
						isLoading || data.status === "initiated" ? (
							
							<CardBody>
								<dl className="row">
 	 								
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  								</dl>
								
							</CardBody>
						) : (
							<CardBody>
								<dl className="row">
									<dt className="col-sm-3">Date</dt>
  									<dd className="col-sm-9">{moment.unix(data.createdAt.seconds).format("DD/MM/YYYY à HH:mm")}</dd>
									<dt className="col-sm-3">Type de paiement</dt>
  									<dd className="col-sm-9">{paymentType}</dd>
  									<dt className="col-sm-3">Statut</dt>
  									<dd className="col-sm-9">{status && `${status.label} - ${status.description}`}</dd>
  									<dt className="col-sm-3">Lien vers le paiement</dt>
  									<dd className="col-sm-9">{settings.checkoutUrl}/{data.id}</dd>
  								</dl>
							</CardBody>
						)
					}
				</Card>
				<Card>
					<CardHeader>
						<div className="d-flex justify-content-between">
							Informations associées au paiement
							{
								(isLoading || data.status === "initiated") ? null : (
									<Link to={`/payments/${id}/update-metadata`} className="btn btn-secondary btn-sm"><span className="oi oi-pencil"></span> Modifier les informations</Link>
								)
							}
						</div>
					</CardHeader>
					{
						isLoading || data.status === "initiated" ? (
							<CardBody>
								<dl className="row">
									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  								</dl>
  								<dl className="row">
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  								</dl>								
							</CardBody>
						) : (
							<CardBody>
								<dl className="row">
  									<dt className="col-sm-3">Agence</dt>
  									<dd className="col-sm-9">{data.store ? data.store : "Pas d'agence associé"}</dd>
  									<dt className="col-sm-3">N° contrat / bon de vente</dt>
  									<dd className="col-sm-9">{data.order.contractId}</dd>
  								</dl>
  								<dl className="row">
  									<dt className="col-sm-3">Code client</dt>
  									<dd className="col-sm-9">{data.customer.customerCode}</dd>
  									<dt className="col-sm-3">Email</dt>
  									<dd className="col-sm-9">{data.customer.email}</dd>
  									<dt className="col-sm-3">Téléphone</dt>
  									<dd className="col-sm-9">{data.customer.phone}</dd>
  								</dl>
							</CardBody>

						)
					}
				</Card>
				<Card>
					<CardHeader>Détails de la Capture</CardHeader>
					{
						isLoading || data.status === "initiated" ? (
							<CardBody>
								<dl className="row">
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  									<dd className="col-sm-9"><Skeleton count={1} height={20} /></dd>
  								</dl>
							</CardBody>
						) : (
							data.status === "succeeded" ? (
								<CardBody>
									<dl className="row">
										<dt className="col-sm-3">Statut</dt>
	  									<dd className="col-sm-9">Capturé</dd>
	  									<dt className="col-sm-3">Montant</dt>
	  									<dd className="col-sm-9">{displayAmount(data.paymentIntent.amount_received)}</dd>
	  									<dt className="col-sm-3">Date de capture</dt>
	  									<dd className="col-sm-9">{data.capturedAt ? moment.unix(data.capturedAt.seconds).format("DD/MM/YYYY à HH:mm") : "-"}</dd>
	  								</dl>
								</CardBody>
							) : (
								<CardBody>
									<dl className="row">
	  									<dt className="col-sm-3">Status</dt>
	  									<dd className="col-sm-9">Non capturé</dd>
	  									<dt className="col-sm-3">Montant</dt>
	  									<dd className="col-sm-9">-</dd>
	  									<dt className="col-sm-3">Date de capture</dt>
	  									<dd className="col-sm-9">-</dd>
	  								</dl>
								</CardBody>
							)
						)
					}
				</Card>
				{
					!(isLoading || data.status === "initiated" || hide) && (
						<Card style={{}}>
							<CardHeader>Stripe Object</CardHeader>
							<CardBody>
								<ReactJson 
									src={data}  
									theme="bright"
									collapsed={false}
									onAdd={false}
									onEdit={false}
									onDelete={false}
									displayObjectSize={false}
									enableClipboard={false}
									indentWidth={4}
									displayDataTypes={false}
									iconStyle="triangle"
								/>
							</CardBody>
						</Card>
					)
				}
				
			</PageSection>
			<Switch>
				<Route path="/payments/:id/send-sms-notification" exact strict component={SendSMSNotificationModal} />
				<Route path="/payments/:id/update-metadata" exact strict component={UpdateMetaDataModal} />
				<Route path="/payments/:id/capture" exact strict component={CaptureModal} />
				<Route path="/payments/:id/cancel" exact strict component={CancelModal} />
			</Switch>
		</Page>
	)
}


