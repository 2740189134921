import React, { useEffect } from 'react'
import { useAsyncData2 } from 'loxamtraining-core'

const TextData = (props: any) => {
	const path = props.refPath ? `${props.refPath}/${props.data}` : undefined
	const [isLoading, data, setData, setLoading] = useAsyncData2(path)

	useEffect(() => {
		if(!props.refPath) {
			setData(props.data)
			setLoading(false)
		}
	}, [])

	return <React.Fragment>{isLoading ? "loading..." : data ? props.keyName ? data[props.keyName] : data : "-"}</React.Fragment>
}


export default TextData