import React from 'react'
import { FormGroup, Label, InputProps, FormText } from 'reactstrap'
import { FieldProps as FieldFormikProps} from 'formik'

import Field from '../Field'


interface CheckboxFieldComponentProps extends FieldFormikProps, InputProps {
	label?: string,
	helptext?: string,
	name: string,
	form: any
	options?: any[]
}


const CheckboxFieldComponent: React.FC<CheckboxFieldComponentProps> = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
})  => {
	// console.log(field.value)
	// console.log(field.name)
	const handleChange = () => {
		setFieldValue(field.name, !field.value)
	}

	return (
		<FormGroup>
			<div className="custom-control custom-switch" onClick={handleChange}>
				<input {...field} {...props} type="checkbox" checked={field.value === true} onChange={handleChange} className="custom-control-input" id={props.id} />
				<Label className="custom-control-label" for={props.id}>{props.label} {props.required && "*"}</Label>
			</div>
			{touched[field.name] && errors[field.name] && <FormText color="error" className="invalid-feedback">{errors[field.name]}</FormText>}
			{props.helptext && <FormText>{props.helptext}</FormText>}		
		</FormGroup>
	)
}


const CheckboxField = (props: any) => <Field component={CheckboxFieldComponent} {...props} />


export default CheckboxField