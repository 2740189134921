import React, {useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardHeader, CardBody, Button } from 'reactstrap'
import { FirestoreClient, AuthClient } from 'loxamtraining-core'
import { Form, TextField, EmailField } from 'loxamtraining-form'

import { Loading } from '../../lib/apptheme'


const SettingsProfile: React.FC = () => {
	const authClient = new AuthClient()
	const firestoreClient = new FirestoreClient()
	const history = useHistory()
	const [isLoading, setLoading] = useState(true)
	const [initialValues, setInitialValues] = useState({email: null})

	useEffect(() => {
		authClient.currentUser().then((user: any) => {
			setInitialValues(user)
			setLoading(false)
		})

	}, [])


	const handleLogout = () => {
        const authClient = new AuthClient()

        authClient.logout().then(() => {
            history.push('/login')
        })
    }


	const handlSubmit = async (values: any, actions: any) => {
		const user = Object.assign({}, values)
		const id = user.id
		delete user.id

		try {
			if (initialValues && initialValues.email !== values.email) {
				await authClient.updateEmail(values.email)
			}
			await firestoreClient.update(`/users/${id}`, user)
			
		} catch(error) {
			error && error.message ? (
				actions.setStatus(error.message)
			) : (
				actions.setStatus("Erreur durant la mise à jour")
			)
		}


		actions.setSubmitting(false)

		
	}

	return (
		<React.Fragment>
			<Card style={{minHeight: "100px"}}>
				<CardHeader>Compte</CardHeader>
				{
					isLoading ? <Loading /> : (
						<CardBody>
							<Form onSubmit={handlSubmit} initialValues={initialValues}>
								<EmailField label="Email" id="email" name="email" required />
								<TextField label="Prénom" id="firstName" name="firstName" required />
							    <TextField label="Nom" id="lastName" name="lastName" required />
							</Form>
						</CardBody>
					)
				}
			</Card>
			
			<Card>
				<CardHeader>Déconnexion du compte</CardHeader>
				<CardBody>
					<Button type="button" onClick={handleLogout} color="secondary">Se déconnecter</Button>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

export default SettingsProfile